module.exports={


                         /* Vasanth server URL'S
                   ================================== */
         URL:'https://core.fe.stage.noton.dev/noton/graphql',
         Auth:'https://core.fe.stage.noton.dev/identity/oauth/token'

} 


