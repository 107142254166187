import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import { Drawer } from 'antd'
import style from './style.module.scss'

const Footer = () => {
  const [visible, setVisible] = useState(false)
  const settings = useSelector(state => state.settings)

  const showDrawer = () => {
    setVisible(true)
  }

  const onClose = () => {
    setVisible(false)
  }

  return (
    <div
      className={classNames(style.footer, {
        [style.footerFullWidth]: settings.isContentNoMaxWidth,
      })}
    >
      <div className={style.inner}>
        <div className="row">
          <div className="col-md-8">
            <div>
              <img
                src="resources/images/notonlogo2.png"
                alt="Noton logo"
                style={{ height: '50px' }}
              />
            </div>
            <p>&copy;2020 NOTON Developer Tool</p>
          </div>
          <div
            style={{ cursor: 'pointer' }}
            className="col-md-4"
            onClick={showDrawer}
            onKeyDown={e => {
              if (e.key === 'Enter') showDrawer()
            }}
            role="button"
            tabIndex={0}
          >
            <div className={style.logo}>
              <img style={{ height: '5em' }} src="resources/images/logo.png" alt="Air UI" />
            </div>
          </div>
        </div>
      </div>
      <Drawer
        width="25%"
        closable={false}
        placement="right"
        bodyStyle={{ padding: '0', height: '100%' }}
        // closable
        onClose={onClose}
        visible={visible}
      >
        <iframe
          title="Noton Bot"
          src="https://cne.test.noton.dev/"
          id="myIframe"
          style={{
            width: '-webkit-fill-available',
            height: '99vh',
            border: 'none',
            // paddingTop: '2em',
            overflowY: 'scroll',
            // paddingBottom: '3vh',
          }}
        />
      </Drawer>
    </div>
  )
}

export default Footer
